<template>
  <div>
    <div>
      <div class="st-container">
        <!-- start contact us -->
        <section id="contact-us" class="contact-us py-5 --text-dark">
          <div>
            <h1 class="s-h1 mb-5 --text-dark">Contact us</h1>
          </div>
          <div class="row">
            <div class="col-lg-7">
              <div class="d-flex flex-column justify-content-between h-100">
                <div>
                  <p class="s-h6">
                    For general enquires, please fill out the form and we’ll
                    reply <br />to you as soon as possible.
                  </p>
                </div>
                <div>
                  <p class="s-h7 mb-0 fw-semibold">Address</p>
                  <p class="s-h7 mb-0">
                    28 Thistlethwaite St <br />South Melbourne VIC 3205
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="h-100 mt-4 mt-lg-0">
                <form
                  name="ff_form1"
                  id="ff_form1"
                  enctype="multipart/form-data"
                  accept-charset="utf-8"
                  class="bfQuickMode"
                  @submit.prevent="submit('/submit_contact_us_form/')"
                >
                  <span
                    class="-bg-lighr-danger d-block w-75 mb-3 rounded text-muted fw-normal s-h7 border border-danger py-2 text-danger"
                    v-if="errors != 'Privacy Policy is Required' && errors"
                  >
                    <ul class="mb-0">
                      <li v-for="(err, key) in errors">
                        {{ key }} {{ err[0].message }}
                      </li>
                    </ul>
                  </span>
                  <span
                    class="-bg-lighr-danger d-block w-75 mb-3 rounded text-muted fw-normal s-h7 border border-danger py-2 text-danger"
                    v-if="errors == 'Privacy Policy is Required'"
                  >
                    <ul class="mb-0">
                      <li>
                        {{ errors }}
                      </li>
                    </ul>
                  </span>
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control border-0 border-bottom border-dark rounded-0 --text-dark ps-0 s-h6 w-75"
                      id="exampleInputEmail1"
                      placeholder="Name"
                      name="name"
                      v-model="message.name"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="email"
                      class="form-control border-0 border-bottom border-dark rounded-0 --text-dark ps-0 s-h6 w-75"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      name="email"
                      v-model="message.email"
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <section
                      class="bfElemWrap bfLabelLeft w-75"
                      id="bfElemWrap4"
                    >
                      <label id="bfLabel4" class="s-h6 d-block mb-1"
                        >My enquiry is about</label
                      >
                      <select
                        class="mb-2 border-bottom border-dark border-0 bg-body py-3 w-100"
                        name="about"
                        id="about-inquery"
                      >
                        <option
                          :value="option.id"
                          v-for="option in about_choices"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </section>
                  </div>

                  <div class="mb-3">
                    <textarea
                      class="form-control border-0 border-bottom border-dark rounded-0 --text-dark ps-0 s-h6 w-75 resize"
                      id="exampleFormControlTextarea1"
                      placeholder="Type message here"
                      rows="3"
                      name="message"
                      v-model="message.message"
                      required
                    ></textarea>
                  </div>
                  <div class="mb-3 ps-0 form-check">
                    <div class="d-flex align-items-center">
                      <div class="form-group-c w-auto mb-0 me-2 -h-24">
                        <input
                          type="checkbox"
                          id="privacy_consent"
                          name="privacy_consent"
                          value="privacy_consent"
                          v-model="checkedNames"
                        />
                        <label for="privacy_consent"></label>
                      </div>
                      <label
                        class="form-check-label s-h8 d-flex align-items-center"
                        for="exampleCheck1"
                        >I accept the
                        <router-link
                          to="/privacy-policy/"
                          class="link --text-dark -text-decoration px-2"
                          >privacy policy</router-link
                        ></label
                      >
                    </div>
                  </div>
                  <div class="" v-if="status != 'submitted'">
                    <vue-recaptcha
                      ref="recaptcha"
                      sitekey="6Ldeb1snAAAAAAtXG5xNO_VkTQUFD6XWqE9kJ5vn"
                      @verify="onCaptchaVerified"
                    ></vue-recaptcha>
                    <div class="d-flex align-items-center mt-4">
                      <div class="d-flex align-items-center">
                        <img
                          src="/static/css/images/affirm/Group55.svg"
                          alt="Group 55"
                          class="me-1"
                          width="25px"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          id="bfSubmitButton"
                          class="bfSubmitButton button border-0 s-h6 bg-body"
                          :disabled="
                            status === 'submitting' || !captchaComplete
                          "
                        >
                          <span class="">Submit enquiry</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <h3 v-if="status === 'submitted'" class="success-message">
                    Thank you for your enquiry
                  </h3>
                </form>
              </div>
            </div>
          </div>
        </section>
        <!-- end contact us -->
      </div>
    </div>

    <div class="-bg-brown --text-dark">
      <div class="st-container">
        <!-- start contact us -->
        <section id="contact" class="py-5">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5">
              <div>
                <p class="s-h7 fw-bold mb-0">General enquiry</p>
                <p class="s-h7 mb-0">Ph: (03) 8695 9623</p>
                <p class="s-h7"><a href="mailto:info@affirmpress.com.au" class="--text-dark link">Email: info@affirmpress.com.au</a></p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5">
              <div>
                <p class="s-h7 fw-bold mb-0">Distribution enquiry</p>
                <p class="s-h7 mb-0">Alliance Distribution Services</p>
                <p class="s-h7 mb-0">Ph: (02) 4390 1300</p>
                <p class="s-h7"><a href="mailto:lauren@affirmpress.com.au" class="--text-dark link">Email: lauren@affirmpress.com.au</a></p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5">
              <div>
                <p class="s-h7 fw-bold mb-0">Media enquiry</p>
                <p class="s-h7 mb-0">Ph: (03) 8695 9623</p>
                <p class="s-h7"><a href="mailto:publicity@affirmpress.com.au" class="--text-dark link">Email: publicity@affirmpress.com.au</a></p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5">
              <div>
                <p class="s-h7 fw-bold mb-0">Sales enquiry</p>
                <p class="s-h7 mb-0">
                  Keiran Rogers
                </p>
                <p class="s-h7 mb-0">Ph: 0419 554 517</p>
                <p class="s-h7"><a href="mailto:keiran.rogers@affirmpress.com.au" class="--text-dark link">Email: keiran.rogers@affirmpress.com.au</a></p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5">
              <div>
                <p class="s-h7 fw-bold mb-0">Rights enquiry</p>
                <!-- <p class="s-h7 mb-0">Keiran Rogers, Sales Director</p> -->
                <p class="s-h7 mb-0">Ph: (03) 8695 9623</p>
                <p class="s-h7"><a href="mailto:info@affirmpress.com.au" class="--text-dark link">Email: info@affirmpress.com.au</a></p>
              </div>
            </div>
          </div>

          <div class="row pt-3 pt-lg-5 pb-2 pb-lg-4">
            <div class="col-12 col-lg-8 d-flex align-content-center">
              <div>
                <p class="fw-bold s-h9">
                  Affirm Press is located on Boon Wurrung Country
                </p>
                <p class="s-h9">
                  We acknowledge the Traditional Owners, the Wurundjeri and Boon
                  Wurrung people of the Kulin Nation, on whose land we meet,
                  share and work. We pay our respects to Elders past and present
                  and extend our respect to Aboriginal and Torres Strait
                  Islander people from all nations of this land.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div
                class="d-flex align-items-center justify-content-start justify-content-lg-end h-100"
              >
              <!-- facebook -->
                <a
                  href="https://www.facebook.com/affirmpress"
                  target="_blank"
                  class="text-decoration-none text-dark me-4 rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown"
                >
                  <i class="fa fa-facebook-f"></i>
                </a>
                <!-- instagram -->
                <a
                  href="https://www.instagram.com/affirmpress/"
                  target="_blank"
                  class="text-decoration-none text-dark me-4 rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown"
                >
                  <i class="fa fa-instagram"></i>
                </a>
                <!-- twitter -->
                <a
                  href="https://twitter.com/affirmpress"
                  target="_blank"
                  class="text-decoration-none text-dark me-lg-4 rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown"
                >
                  <i class="fa fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="d-flex align-items-center h-100">
                <img src="/static/affirm-logo.svg" alt="logo" width="149px" />
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div
                class="d-flex align-items-center justify-content-start justify-content-lg-end h-100"
              >
                <div>
                  <p class="fw-bold s-h9">Copyright © Affirm Press</p>
                </div>
                <div class="ms-5">
                  <router-link
                    class="text-decoration-none --text-dark link"
                    to="/privacy-policy/"
                  >
                    <p class="fw-bold s-h9">View privacy</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- end contact us -->
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../mixins/Form.js";
export default {
  name: "affirm-contact-us",
  mixins: [Form],
  data() {
    return {
      checkedNames: "",
      message: {
        name: "",
        email: "",
        message: "",
        about: "",
      },
      errors: null,
    };
  },
  methods: {},
  computed: {
    about_choices: function () {
      return this.$store.state.site_data.contact_us_about_choices;
    },
  },
  mounted() {},
};
</script>
<style>
select#about-inquery {
  float: left;
  width: 77%;
  border-color: #ddd;
}
.-bg-lighr-danger {
  background: #ffd7d7;
}
</style>
