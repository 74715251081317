<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin acc-search">
      <div id="vmss-result-list-loading">
        <div id="vmss-result-list">
          
          <slot />
         <!--  <div class="top-category-search">
            <top-book-categorys></top-book-categorys>
          </div> -->
          <br><br>
          <!-- <h2>Books</h2> -->
          <looping-rhombuses-spinner
              :animation-duration="2500"
              :rhombus-size="15"
              color="#febe10"
              v-if="show_spinner"
              class="loader-spinner"
          />
          <div class="vmss_search_component_summary" v-if="num_pages > 0">
            <p>
              <span>Results:</span>
              <span>{{search_items_range}}</span>
            </p>
          </div>
          <div class="vm-browseview layout-set layout-set--2-8">
            <template v-for="book in books">
              <div class="row">
                <div v-if="host!='acc' && book.retail_price <= 0">
                </div>
                <div v-else class="product item">
                  <div class="block-1">
                    <div class="section-image">
                      <router-link class="vm-image-box" :to="getBookUrl(book)">
                        <img v-if="host=='acc'"
                          :src="'https://s3-ap-southeast-2.amazonaws.com/dimoacc/covers/'+book.isbn+'.jpg'"
                          @error="noCoverImage"
                        />
                        <img v-else-if="host=='affirm'"
                          :src="getCoverLinkMedium(book)"
                          @error="noCoverImage"
                        />
                        <img v-else
                          :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+book.isbn+'.jpg'"
                          @error="noCoverImage"
                        />
                      </router-link>
                      <div v-if="book.retail_price > 0">
                        <add-to-cart :isbn="book.isbn" place="search" :title="book.nonsortprefix + ' ' + book.title" :book='book'></add-to-cart>
                      </div>
                      <div v-else class="factsheet-button width100">
                        <a v-on:click="categoryData(book.isbn)" href="javascript:void(0)" style="color:white;" target=”_blank” onclick="_gaq.push(['_trackEvent','Download','PDF',this.href]);">Download Fact Sheet</a>
                      </div>
                    </div>
                  </div>
                  <div class="block-2 section-description">
                    <h2>
                      <router-link :to="getBookUrl(book)">{{book.nonsortprefix}} {{book.title}}</router-link>
                    </h2>
                    <p class="vm-sub-text">{{book.author}}</p>
                    <!-- <p>
                      <span class="vm-sub-text">Format:</span>
                      {{book.package_type}}
                    </p> -->
                     <div class="product-price">
                      <div class="PricesalesPrice">
                        <span v-if="book.retail_price > 0" class="PricesalesPrice">{{currencySymbol}}{{book.retail_price}}</span>
                        <span v-else class="PricesalesPrice"><span v-if="host=='acc'">Free to download</span></span>
                      </div>
                    </div>
                    <p class="product_s_desc" v-html="book.key_note_blurb"></p>
                    <p>
                      <router-link :to="getBookUrl(book)">Read more »</router-link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="horizontal-separator"></div>
            </template>
            <p v-show="books.length == 0 && !show_spinner">There are no books for this search term</p>
          </div>
        </div>
      </div>
      <form class="pagination-form" v-if="host=='allenandunwin' || host=='allenandunwinnz'">
        <div class="pagination">
          <div class="list-footer">
            <ul>
              <li class="pagination-start">
                <a class="pagenav page-disable" v-if="page==1" > Start </a>
                <router-link :to="'/search' +'?page=1'+ getUrlParams(1)" v-else>Start</router-link>
              </li>
              <li class="pagination-prev">
                <a class="pagenav page-disable" v-if="page==1" > Prev </a>
                <router-link :to="'/search'+'?page='+ prev_page + getUrlParams(prev_page)" v-else>Prev</router-link>
              </li>
              <li v-for="page_num in prev_pages">
                <router-link
                  :title="page_num"
                  :to="'/search'+'?page=' + page_num + getUrlParams(page_num)"
                  class="pagenav"
                >{{page_num}}</router-link>
              </li>
              <li>
                <span class="pagenav">{{page}}</span>
              </li>
              <li v-for="page_num in next_pages">
                <router-link
                  :title="page_num"
                  :to="'/search'+'?page=' + page_num + getUrlParams(page_num)"
                  class="pagenav"
                >{{page_num}}</router-link>
              </li>
              <li class="pagination-next">
                <a class="pagenav page-disable" v-if="page==num_pages" > Next </a>
                <router-link
                  title="Next"
                  :to="'/search'+'?page=' + next_page + getUrlParams(next_page)"
                  class="pagenav"
                v-else >Next</router-link>
              </li>
              <li class="pagination-end">
                <a class="pagenav page-disable" v-if="page==num_pages" > End </a>
                <router-link
                  title="End"
                  :to="'/search'+'?page=' + num_pages + getUrlParams(num_pages)"
                  class="pagenav"
                v-else>End</router-link>
              </li>
            </ul>
            <div class="counter">Page {{page}} of {{num_pages}}</div>
          </div>
        </div>
      </form>

      <form class="pagination-form" v-else>
        <div class="pagination">
          <div class="list-footer">
            <ul>
              <!-- <li class="pagination-start">
                <router-link :to="'/search' +'?page=1'+ getUrlParams(1)">Start</router-link>
              </li> -->
              <li class="pagination-prev">
                <router-link :to="'/search'+'?page='+ prev_page + getUrlParams(prev_page)">Prev</router-link>
              </li>
              <li>
                <span class="pagenav">{{page}}</span>
              </li>
              <li v-for="page_num in next_pages">
                <router-link
                  :title="page_num"
                  :to="'/search'+'?page=' + page_num + getUrlParams(page_num)"
                  class="pagenav"
                >{{page_num}}</router-link>
              </li>
              <li class="pagination-next">
                <router-link
                  title="Next"
                  :to="'/search'+'?page=' + next_page + getUrlParams(next_page)"
                  class="pagenav"
                >Next</router-link>
              </li>
              <!-- <li class="pagination-end">
                <router-link
                  title="End"
                  :to="'/search'+'?page=' + num_pages + getUrlParams(num_pages)"
                  class="pagenav"
                >End</router-link>
              </li> -->
            </ul>
            <div class="counter">Page {{page}} of {{num_pages}}</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadCrumb from "../components/BreadCrumb";
import SideBar from "../components/SideBar";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Currency from "../mixins/Currency";
import AddToCart from "../components/AddToCart";
import Book from "../mixins/Book";
import TopCategoryBook from "../components/TopCategoryBook";
export default {
  name: "search",
  components: {
    LoopingRhombusesSpinner,
    BreadCrumb,
    SideBar,
    AddToCart,
    "top-book-categorys":TopCategoryBook,
  },
  mixins: [Currency, Book],
  data() {
    return {
      host:'',
      search_term: "",
      num_pages: 0,
      page_num: 1,
      next_page: null,
      prev_page: null,
      next_pages: [],
      prev_pages:[],
      search_items_range: "",
      show_spinner: true,
      page: 1,
      books: [],
    };
  },
  methods: {
    categoryData(isbn)
    {  
      var link = "https://s3-ap-southeast-2.amazonaws.com/dimoacc/factsheets/"+isbn+".pdf"
      window.open(link)
    },
    search_books(search_sort) {
      let self = this;
      self.books = [];
      self.show_spinner = true;
      let sort_code = search_sort;
      if (!search_sort) {
        sort_code = localStorage["name"];
      }
      localStorage["name"]=sort_code
      if (!sort_code) {
        sort_code = "rel";
      }
      // Set site_code based on the host
      let site_code = '';
      switch (this.$store.state.site_data.host) {
        case 'allenandunwin':
          site_code = 'AU';
          break;
        case 'allenandunwinnz':
          site_code = 'NZ';
          break;
        case 'murdoch':
          site_code = 'MU';
          break;
        case 'murdochuk':
          site_code = 'UK';
          break;
        case 'acc':
          site_code = 'ACC';
          break;
        default:
          site_code = '';
          break;
      }
      axios
        .get(`/search_api/?page=${this.page}`, {
          params: {
            sort: sort_code,
            bic: this.$route.query.bic,
            term: this.$route.params.term,
            site_code: site_code,
            ...this.$route.query,
          },
        })
        .then(function (data) {
          if (data.status == 200) {
            self.num_pages = data.data.num_pages;
            self.books = data.data.books;
            self.page = data.data.page;
            self.next_pages = data.data.next_pages;
            self.prev_pages = data.data.prev_pages;
            self.next_page = data.data.next_page;
            self.prev_page = data.data.prev_page;
            self.search_items_range = data.data.search_items_range;
            setTimeout(() => {
              self.show_spinner = false;
            }, 10);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.show_spinner = false;
        });
    },
    noCoverImage(event) {
      if (this.$store.state.site_data.host== 'acc'){
        event.target.src = "/static/images/acc/No_Image_Available.jpg";
      }
      else if (this.$store.state.site_data.host== 'affirm'){
        event.target.src = '/static/css/images/affirm/No_Image_Available.jpg';
      }
      else if(this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
          event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
      }
    },
    getCoverLinkMedium(book){
      if (book.coverurl == 'y'|| book.coverurl == 'Y'){
        return `https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/${book.isbn}.jpg`;
      }
      else{
        return '/static/css/images/affirm/No_Image_Available.jpg';
      }
    },
    getUrlParams(page_num) {
      let params = this.$route.query;
      // params['page'] = page_num;
      delete params['page'];
      var queryString = Object.keys(params).map((key) => {
          if(!params[key]){
            return encodeURIComponent(key);
          }else{
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
          }
      }).join('&');
      return '&' + queryString;
    },
  },
  computed: {
    // getUrlParams() {
    //   // let params = ''
    //   // for(var key in this.$route.query){
    //   //   params += `${key}=${this.$route.query[key]}&`;
    //   // }
    //   // return params;
    //   let url = window.location.href;
    //   let hashes = url.split("?")[1];
    //   return '&'+hashes;
    // },
  },
  beforeRouteUpdate(to, from, next) {
    // check if there is a next page
    if (to.query.keyword) {
      this.search_term = to.query.keyword;
    }
    if(to.query.page){
      this.page = to.query.page;
    }
    this.search_books();
    next();
    window.scrollTo(0, 0);
  },
  mounted() {
    localStorage["name"]="rel";
    this.host = this.$store.state.site_data.host;
    // initial page_num variable if the link has a page number
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }else{
      this.page = 1;
    }
    this.search_term = this.$route.query.keyword;
    this.search_books();
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query.page"() {
      console.log(this.$route.query.page);
      if (this.$route.query.page) {
        this.page = this.$route.query.page;
      }
      this.search_term = this.$route.query.keyword;
      this.search_books();
      window.scrollTo(0, 0);
    },
    '$route'(){
      this.search_books();
    }
  },
};
</script>

<style>
.search_again {
  padding: 5px 45px;
}
.vm-browseview .addtocart-bar > * {
  display: block;
}
.quantity-input {
  max-width: 40%;
  margin-bottom: 10px;
}
.addtocart-button {
  font-size: 1.2em;
}
.top-category-images{
  width:20%;
  float:left;
  margin-right:10px;
  height:208px;
}
.vmss_mixed_image{
  position: relative;
  overflow: hidden;
  width: 100%;
}
.l-main .moduletable:first-child {
    margin-top: 0;
}
.vmss_mixed_image__item:first-child {
    padding-left: 3.167%;
    margin-left: 0;
}
.factsheet-button {
  background-color: #ed783b;
  height: 23px;
}
.loader-spinner{
  padding-top:0px;
  padding-bottom:0px;
}
a.pagenav.page-disable {
    opacity: 0.5;
}
a.pagenav.page-disable:hover {
    background: #72838d;
}
</style>